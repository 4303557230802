import {
  Html,
  OrbitControls,
  useProgress
} from "@react-three/drei";
import { Canvas, useFrame, useLoader, dispose, useGLTF  } from "@react-three/fiber";
import React, { Suspense, useEffect, useRef, useState, useMemo } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import {proxy, useSnapshot} from 'valtio';
import * as THREE from "three";
import ReactPlayer from 'react-player';

const state = proxy({
  currentId: null,
  currentTitle: null,
  isHovered: true
});

const Loader = () => {
  const { progress } = useProgress();
  return <Html center wrapperClass="model-loader">{progress} % loaded</Html>;
}

const Logo = ({annotationsConfig, stopVideo, toggleStopVideo}) => {
  const isAnnotationsAvailable = annotationsConfig !== null ? true : false;
  const annotations = [];

  const [isExpandVideo, setExpandVideo] = useState(null);
  const [isVideoPlaying, setVideoPlaying] = useState({id:null, status:false}); 
  // GLTF model parser
  const {scene} = useLoader(
    GLTFLoader,
    "newModels/AiDEsymmetry_highMesh_nodes_14_1.gltf"
  );

  const copiedScene = useMemo(() => scene.clone(), [scene])

  // Creating model template reference
  const logoRef = useRef();

  // Records the current state of model epsecially rotating position
  const snap = useSnapshot(state);

 useEffect(()=> {
    if(stopVideo) {
      setVideoPlaying({id:null, status:false});
      toggleAnnotationPopup(true, null, null);
      setExpandVideo(null);
    }
  }, [stopVideo]);

  /* individual State, Const, Vector variables are required to keep track of each annonatating node position as model is rotating */
  const [isInRange0, setInRange0] = useState(false);
  const isVisible0 = isInRange0;
  const vec0 = new THREE.Vector3();

  const [isInRange1, setInRange1] = useState(false);
  const isVisible1 = isInRange1;
  const vec1 = new THREE.Vector3();

  const [isInRange2, setInRange2] = useState(false);
  const isVisible2 = isInRange2;
  const vec2 = new THREE.Vector3();

  const [isInRange3, setInRange3] = useState(false);
  const isVisible3 = isInRange3;
  const vec3 = new THREE.Vector3();

  const [isInRange4, setInRange4] = useState(false);
  const isVisible4 = isInRange4;
  const vec4 = new THREE.Vector3();

  const [isInRange5, setInRange5] = useState(false);
  const isVisible5 = isInRange5;
  const vec5 = new THREE.Vector3();
  /* individual State, Const, Vector variables are required to keep track of each annonatating node position as model is rotating */


  // Toggle annotation extended/descriptive popup appearing on annotated node hover
  const toggleAnnotationPopup = (isHovered,currentId, currentTitle)=> {
    state.isHovered = isHovered; 
    state.currentId = currentId;
    state.currentTitle = currentTitle;
  }

  // Render annotation template based on node's visibility (based on front-facing or back-facing)
  const renderAnnotationChip = (o, annotationConfig, isVisible) => {
    if (o.userData.name && o.userData.name === annotationConfig.nodeName) {
      annotations.push(
        <Html key={o.uuid} 
              wrapperClass={`chip-default ${isVisible ? "show-chip": "hide-chip"} ${state.currentId === annotationConfig.id ? "active" : "inactive"} ${isExpandVideo === annotationConfig.id ? 'reset-transform':''}`}
              position={[o.position.x, o.position.y, o.position.z]}
              occlude={false} // Must be false as few nodes aren't set in range of camera. 
        >
          <div onMouseOver={()=> {
            if(annotationConfig.videoUrl) {
                if(annotationConfig.id !== isVideoPlaying.id) {
                  setVideoPlaying({id: null, status:false});
                  toggleStopVideo(false);
                }
                toggleAnnotationPopup(false, annotationConfig.id, annotationConfig.title);
              }
            }} 
            onMouseLeave={()=> {
              if(annotationConfig.videoUrl) {
                if(!isVideoPlaying.status && !isExpandVideo) {
                  toggleAnnotationPopup(true, null, null);
                  setExpandVideo(null);
                  toggleStopVideo(false);
                }
              }  
            }}
            className="annotation-wrapper"
          >
            <div className={`annotation ${isExpandVideo === annotationConfig.id ? 'hide-title':''}`}>
              {annotationConfig.videoUrl && <img src="images/mvideo-play-icon.svg" className="annotation-with-video" alt="video-icon" />}
              <span className="annotation-title">
                {annotationConfig.title}
              </span>
            </div>
            {annotationConfig.videoUrl && <div className={`annotation-expansion-container ${state.currentId === annotationConfig.id ? "show-chip-popup active": "hide-chip-popup"} ${isExpandVideo === annotationConfig.id ? 'expand-video-container':''}`}>
              <div className="annotation-expansion-header">
                <h3>
                  {annotationConfig.description}  
                </h3>
                {isExpandVideo !== annotationConfig.id && <span className="video-expand" onClick={()=> {setExpandVideo(state.currentId);}}><img src="images/mvideo-expand-icon.svg" alt="expand video"/></span> }
                {isExpandVideo === annotationConfig.id && <span className="close-video-expand" onClick={()=> {setExpandVideo(null); setVideoPlaying({id: null, status:false}); toggleStopVideo(false); toggleAnnotationPopup(true, null, null);}}>
                  <img src="images/mvideo-close-icon.svg" alt="close video"/>
                </span> }
              </div>
              <div id={`annotation-expansion-video-${annotationConfig.id}`} className="annotation-expansion-video">
                <ReactPlayer 
                  playing={annotationConfig.id === isVideoPlaying.id ? isVideoPlaying.status : false} 
                  url={annotationConfig.videoUrl} 
                  width="100%" 
                  height="100%" 
                  onStart={() =>{setVideoPlaying({id: annotationConfig.id, status:true}); toggleStopVideo(false);}}
                  onPause={() =>{if(!isExpandVideo) {setVideoPlaying({id: null, status:false});}}}
                  onBuffer={() => {setVideoPlaying({id: annotationConfig.id, status:true}); toggleStopVideo(false);}}
                />
              </div>
            </div>}
          </div>
        </Html>
      )
    }
  };

  const isNodeInRange = (state, id, vec, inRange) => {
    let range = false;
    // checking whether node is frontfacing or backfacing
    if(id == 20) {
      range = state.camera.position.distanceTo(logoRef.current.children[id].getWorldPosition(vec)) >= 0.5;
    }
    else {
      range = state.camera.position.distanceTo(logoRef.current.children[id].getWorldPosition(vec)) <= 0.5;
    }
    return (range !== inRange) ? range : inRange;
  }

  const checkAnnotationChipsVisibility = (sceneState, lAnnotationsConfig) => {
    // Sphere_023 - 24
    if(lAnnotationsConfig[0])
    setInRange0(isNodeInRange(sceneState, lAnnotationsConfig[0].nodeId, vec0, isInRange0));

    // Sphere_012 - 8
    if(lAnnotationsConfig[1])
    setInRange1(isNodeInRange(sceneState, lAnnotationsConfig[1].nodeId, vec1, isInRange1));
  
    // Sphere_014 - 6
    if(lAnnotationsConfig[2])
    setInRange2(isNodeInRange(sceneState, lAnnotationsConfig[2].nodeId, vec2, isInRange2));
    
    // Sphere_04 - 2
    if(lAnnotationsConfig[3])
    setInRange3(isNodeInRange(sceneState, lAnnotationsConfig[3].nodeId, vec3, isInRange3));
    
    // Sphere_016 - 20
    if(lAnnotationsConfig[4])
    setInRange4(isNodeInRange(sceneState, lAnnotationsConfig[4].nodeId, vec4, isInRange4));
  
    // Sphere_019 - 10
    if(lAnnotationsConfig[5])
    setInRange5(isNodeInRange(sceneState, lAnnotationsConfig[5].nodeId, vec5, isInRange5));
  }

  useFrame((sceneState) => {
    if(state.isHovered == true) {
      logoRef.current.rotation.y += 0.003;
    }
    if(isAnnotationsAvailable) {
      // checking nodes visibility
      checkAnnotationChipsVisibility(sceneState, annotationsConfig);
    }
  });

  if(isAnnotationsAvailable) {
    // travering scene and rendering annotations based on model rotational position
    copiedScene.traverse((currentNodeObject) => {
      if(annotationsConfig[0])
      renderAnnotationChip(currentNodeObject, annotationsConfig[0], isVisible0);

      if(annotationsConfig[1])
      renderAnnotationChip(currentNodeObject, annotationsConfig[1], isVisible1);

      if(annotationsConfig[2])
      renderAnnotationChip(currentNodeObject, annotationsConfig[2], isVisible2);

      if(annotationsConfig[3])
      renderAnnotationChip(currentNodeObject, annotationsConfig[3], isVisible3);

      if(annotationsConfig[4])
      renderAnnotationChip(currentNodeObject, annotationsConfig[4], isVisible4);

      if(annotationsConfig[5])
      renderAnnotationChip(currentNodeObject, annotationsConfig[5], isVisible5);
    });
  }
  
  return (<>
    <primitive
      ref={logoRef}
      rotation-y={Math.PI * 0.25}
      object={copiedScene}
      position={[0, 0, 0]}
    >
    {annotations}
    </primitive>
    {isExpandVideo && <Html wrapperClass="model-video-overlay"></Html>}
    </>
  );
};

const Scene = ({annotateConfig}) => {
 const [stopVideo, setStopVideo] = useState(false);

  const toggleStopVideo = (value) => {
    setStopVideo(value);
  }

  return (
      <Canvas
        id="aide-3d-model-canvas"
        camera = {{ position: [0, 0, 0.5], zoom:12}}
      >
        <ambientLight intensity={2.8}/>
        <directionalLight position={[10, 10, 1]} intensity={2.8} color={"#544ff8"} />
        <directionalLight position={[-10, -10, -1]} intensity={2.8} color={"#544ff8"} />
        <Suspense fallback={<Loader />}>
          <Logo annotationsConfig={annotateConfig} stopVideo={stopVideo} toggleStopVideo={toggleStopVideo}/>
        </Suspense>
        <OrbitControls
          onChange={()=>{setStopVideo(true);}}
          enableZoom={false}
          enablePan={false}
          target={[0, 0, 0]}
          minPolarAngle={Math.PI / 2}
          maxPolarAngle={Math.PI / 2}
        />   
      </Canvas>
  );
};

export default Scene;
